import React from "react";
import InputField from "components/fields/InputField";

const Radios = ({ options, name, value, onChange }) => {
  return (
    <div className="mt-2">
      <ul className="w-full items-center rounded-lg border border-gray-200 bg-white text-sm font-medium text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:bg-navy-900 dark:text-white sm:flex">
        {options?.map((option, index) => (
          <li
            key={index}
            className="w-full border-b border-gray-200 dark:border-gray-600 sm:border-b-0 sm:border-r"
          >
            <div className="flex items-center ps-3">
              <InputField
                className="h-4 w-4 border-gray-300 bg-gray-100 text-green-600 focus:ring-2 focus:ring-green-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-green-600 dark:focus:ring-offset-gray-700"
                type="radio"
                id={`${name}-${index}`}
                name={name}
                value={option.value}
                disabled={
                  option.value === "model2" || option.value === "model3"
                }
                checked={value === option.value}
                onChange={onChange}
              />
              <label
                htmlFor={`${name}-${index}`}
                className="ms-2 w-full py-3 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                {option.label}
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Radios;
