import React, { useState, useEffect } from "react";
import useRequest from "services/useRequest";
import sofa from "../../assets/images/sofa.png";
import sofa2 from "../../assets/images/sofa2.png";
import sofa3 from "../../assets/images/sofa3.png";
import sofa6 from "../../assets/images/sofa6.jpeg";
import Card from "../../components/card2";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import banner from "../../assets/images/banner.png";
import Dialog from "components/dialog";
import ProjectForm from "views/Project/";
import Button from "../../components/button";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";

const Home = () => {
  const [projects, setProjects] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { request } = useRequest();

  const fetchProjects = async () => {
    try {
      const response = await request("project");
      setProjects(response);
    } catch (error) {
      console.error("Failed to fetch projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const openDialog = (project, deleting) => {
    setSelectedProject(project);
    setIsDialogOpen(true);
    setIsDeleting(deleting);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedProject(null);
    setIsDeleting(false);
  };

  return (
    <div>
      <div
        className="flex w-full flex-col rounded-[20px] bg-cover px-[30px] py-[30px] md:px-[64px] md:py-[56px]"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="w-full">
          <h4 className="mb-[14px] max-w-full text-xl font-bold text-white md:w-[64%] md:text-3xl md:leading-[42px] lg:w-[46%] xl:w-[85%] 2xl:w-[75%] 3xl:w-[52%]">
            Create, explore your imagination with our tools
          </h4>
          <p className="mb-[40px] max-w-full text-base font-medium text-[#E3DAFF] md:w-[64%] lg:w-[40%] xl:w-[72%] 2xl:w-[60%] 3xl:w-[45%]">
            Enter in this creative world.
          </p>

          <div className="mt-[36px] flex items-center justify-between gap-4 sm:justify-start 2xl:gap-10">
            <Button
              label="Start a new project"
              onClick={openDialog}
              extras="text-black linear rounded-xl bg-white px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white/80 active:!bg-white/70"
            />
            <Button
              label="Start a new project"
              extras="text-base font-medium text-lightPrimary hover:text-lightPrimary 2xl:ml-2"
            />
          </div>
        </div>
      </div>

      <div className="pl-6.5 pr-6.5 mt-1.5 flex items-center justify-center">
        <p className="my-4 text-xl font-bold text-navy-700 dark:text-white">
          Recent projects
        </p>
      </div>

      <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
        {projects?.map((project) => (
          <Card
            key={project._id}
            imageUrl={sofa6}
            title={project.name}
            date={project.createdAt}
            onButtonClick={() => {}}
          >
            <div className="flex h-full flex-row items-center justify-between">
              <div>
                <div>{project.name}</div>
                <div className="text-xs text-gray-400">
                  {project.description}
                </div>
              </div>

              <div className="flex h-full flex-row items-center justify-between">
                <TrashIcon onClick={() => openDialog(project, true)} />
                <Button
                  label="Edit"
                  onClick={() => {
                    window.location.href = `/interiors?projectId=${project._id}`;
                  }}
                  extras="text-white bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                />
              </div>
            </div>
          </Card>
        ))}

        <Card>
          <div className="flex h-full flex-col items-center justify-around">
            <PlusIcon />
            <Button
              label="Create a new project"
              onClick={openDialog}
              extras="text-white bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            />
          </div>
        </Card>
        {isDialogOpen && (
          <Dialog
            isOpen={isDialogOpen}
            onClose={closeDialog}
            title={isDeleting ? "Confirm Delete" : "Create a New Project"}
            name={"Project"}
            form={() => (
              <ProjectForm
                project={selectedProject}
                onClose={closeDialog}
                isDeleting={isDeleting}
              />
            )}
          />
        )}
      </div>

      <div className="pl-6.5 pr-6.5 mt-1.5 flex items-center justify-center">
        <p className="my-4 text-xl font-bold text-navy-700 dark:text-white">
          What you can do with VizuAi
        </p>
      </div>

      <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-3">
        <Card
          heading="Products"
          subheading="Showcase your products"
          imageUrl={sofa}
          description="Make your products shine with our powerful ai. Upload your product and let us showcase your product."
          buttonLabel="Try now"
          btnExtras="text-white bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700"
          onButtonClick={() => {}}
        />

        <Card
          heading="Interiors"
          subheading="Redecorate your interiors"
          imageUrl={sofa2}
          description="Want your room redecorated. Just upload the picture of your current room and let us redecorate it for you"
          buttonLabel="Try now"
          btnExtras="text-white bg-red-500 hover:bg-red-600 active:bg-red-700"
          onButtonClick={() => {}}
        />

        <Card
          heading="Sketch to life"
          subheading="Bring your sketches to life"
          imageUrl={sofa3}
          buttonLabel="Try now"
          btnExtras="text-white bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          description="Do you have a sketch you created and want to bring it to life. Try our Ai tool and expand your ideas."
          onButtonClick={() => {}}
        />
      </div>
    </div>
  );
};

export default Home;
