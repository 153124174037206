import React from "react";

const Button = ({ label, onClick, extras = "", type = "button" }) => {
  return (
    <button
      type={type}
      className={`label-base rounded-[20px] px-6 py-1 font-medium transition duration-200 ${extras}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default Button;
