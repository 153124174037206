import {
  MdHome,
  MdPerson,
  MdBarChart,
  MdOutlineShoppingCart,
  MdDashboard,
  MdLock,
} from "react-icons/md";
import SignIn from "views/auth/SignIn";
import SignUp from "views/auth/SignUp";
import Home from "views/Home";
import Interiors from "views/Interiors";
import Products from "views/Products";
import Sketch from "views/Sketch";
import Profile from "views/Profile";

const routes = [
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "sign-up",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignUp />,
  },
  {
    path: "/home",
    title: "Home",
    element: <Home />,
    layout: "GeneralLayout",
    icon: <MdHome width="1.5rem" height="1.5rem" />,
  },
  {
    path: "/interiors",
    title: "Interiors",
    element: <Interiors />,
    layout: "GeneralLayout",
    icon: <MdBarChart width="1.5rem" height="1.5rem" />,
  },
  {
    path: "#",
    title: "Products",
    element: <Products />,
    layout: "GeneralLayout",
    icon: <MdOutlineShoppingCart width="1.5rem" height="1.5rem" />,
  },
  {
    path: "#",
    title: "Sketch to life",
    element: <Sketch />,
    layout: "GeneralLayout",
    icon: <MdDashboard width="1.5rem" height="1.5rem" />,
  },
  {
    path: "#",
    title: "Profile",
    element: <Profile />,
    layout: "GeneralLayout",
    icon: <MdPerson width="1.5rem" height="1.5rem" />,
  },
];
export default routes;
