import React from "react";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import Button from "../../components/button";

const FavouriteCard = ({ buttonText, imgUrl }) => {
  return (
    <div className="relative m-2">
      <img
        className="w-full rounded-[20px] object-contain 3xl:h-full 3xl:w-full"
        src={imgUrl}
        alt="Room"
      />
      <div className="absolute right-2 top-3">
        <div className="text-black rounded-full bg-white p-2 dark:bg-brand-400 ">
          <IoHeartOutline className="h-3 w-3" />
        </div>
      </div>
      <div className="absolute bottom-2 right-2">
        <Button
          label={buttonText}
          extras="text-white bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        />
      </div>
    </div>
  );
};

export default FavouriteCard;
