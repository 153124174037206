import React from "react";

const Toast = ({ type, message, onClose }) => {
  const toastClasses = `flex items-center w-full max-w-xs p-4 text-gray-500 rounded-lg shadow dark:text-gray-400 ${
    type === "success" ? "bg-green-100" : "bg-red-100"
  }`;
  const iconColor = type === "success" ? "text-green-500" : "text-red-500";

  return (
    <div
      className={toastClasses}
      role="alert"
      style={{ position: "fixed", top: "1rem", right: "1rem", zIndex: 9999 }}
    >
      <div
        className={`inline-flex h-8 w-8 flex-shrink-0 items-center justify-center ${iconColor} rounded-lg dark:bg-green-800 dark:text-green-200`}
      >
        {type === "success" ? (
          <svg
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        ) : (
          <svg
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
        )}
      </div>
      <div className="ml-3 text-sm font-normal">{message}</div>
      <button
        onClick={onClose}
        type="button"
        className="absolute right-2 top-2 -mx-1.5 items-center rounded-full bg-white p-1.5 text-gray-400 hover:bg-gray-100 hover:text-gray-900 focus:ring-2 focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white"
        aria-label="Close"
      >
        <span className="sr-only">Close</span>
        <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414L11.414 10l2.293 2.293a1 1 0 01-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 10 6.293 7.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};

export default Toast;
