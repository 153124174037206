import { MdFileUpload } from "react-icons/md";
import Card from "components/card";
import React from "react";
import Button from "../../components/button";

const Upload = ({ onImageUpload, fileInputRef }) => {
  return (
    <Card className="xlg:h-84 grid w-full grid-cols-1 gap-3 rounded-[20px] bg-white bg-clip-border font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none lg:h-64 2xl:grid-cols-11">
      <div className="col-span-5 flex h-full w-full flex-col justify-center overflow-hidden rounded-xl bg-white pb-4 pl-3 dark:!bg-navy-800">
        <h5 className="text-left text-base font-bold leading-9 text-navy-700 dark:text-white">
          Upload your image
        </h5>
        <p className="leading-1 mt-2 text-sm font-normal text-gray-600">
          Please make sure to upload an image white white background
        </p>

        <Button
          label="Upload Now"
          extras="mt-4 text-white bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={() => fileInputRef.current.click()}
        />

        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          className="hidden"
          onChange={onImageUpload}
        />
      </div>

      <div className="col-span-5 h-full w-full rounded-xl bg-lightPrimary dark:!bg-navy-700 2xl:col-span-6">
        <button className="flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700 lg:pb-0">
          <MdFileUpload className="text-[70px] text-brand-500 dark:text-white" />
          <h4 className="text-lg font-bold text-brand-500 dark:text-white">
            Upload
          </h4>
          <p className="mt-2 text-sm font-medium text-gray-600">
            PNG, JPG files are allowed
          </p>
        </button>
      </div>
    </Card>
  );
};

export default Upload;
