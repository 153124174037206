import { useCallback } from "react";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export default function useRequest() {
  const request = useCallback(async (endpoint, requestConfig) => {
    try {
      const response = await axios.request({
        url: `${baseURL}${endpoint}`,
        ...requestConfig,
        headers: {
          ...requestConfig?.headers,
        },
      });

      if (
        typeof requestConfig?.fullResponse === "boolean" &&
        requestConfig.fullResponse
      ) {
        return response;
      } else {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  }, []);

  return { request };
}
