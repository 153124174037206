import React from "react";
import { ReactComponent as TickPlusIcon } from "../../assets/icons/tickplus.svg";

const Control = ({ title, subtitle, isActive, onClick }) => {
  return (
    <div className="flex items-center" onClick={onClick}>
      <div>
        <TickPlusIcon />
      </div>
      <div className="ml-3">
        <p className={`text-md ${isActive && "font-bold text-brand-500"}`}>
          {title}
        </p>
        <p className={`text-xs ${isActive && "font-bold text-brand-500"}`}>
          {subtitle}
        </p>
      </div>
    </div>
  );
};

export default Control;
