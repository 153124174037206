import React, { useState } from "react";
import InputField from "components/fields/InputField";
import Radios from "components/radios";
import useRequest from "services/useRequest";
import Toast from "components/toast";
import Button from "components/button";
import sofa6 from "assets/images/sofa6.jpeg";

const ProjectForm = ({ onClose, project, isDeleting }) => {
  const [state, setState] = useState(() => ({
    name: "",
    type: "",
    description: "",
    imageFileExtension: sofa6,
  }));

  const { request } = useRequest();
  const [toast, setToast] = useState(null);

  const handleChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const method = isDeleting ? "DELETE" : "POST";
    const url = isDeleting ? `project/${project._id}` : "project";
    const data = isDeleting ? null : state;

    try {
      const response = await request(url, {
        method,
        data,
      });
      if (response) {
        setToast({ type: "success", message: response.message });
        const redirectUrl = isDeleting ? "/home" : "/interiors";
        setTimeout(() => {
          window.location.href = redirectUrl;
        }, 3000);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      setToast({ type: "error", message: errorMessage });
    }
  };

  return (
    <>
      {/* Toast */}
      {toast && (
        <div className="relative">
          <Toast
            type={toast.type}
            message={toast.message}
            onClose={() => setToast(null)}
          />
        </div>
      )}
      <form onSubmit={handleSubmit}>
        {isDeleting && (
          <div className="mb-4 text-sm text-gray-700 dark:text-white">
            Are you sure you want to delete this project?
          </div>
        )}
        {!isDeleting && (
          <>
            <div className="mb-4">
              <label
                className="block text-sm font-medium text-gray-700 dark:text-white"
                htmlFor="name"
              >
                Name
              </label>
              <InputField
                extra="mb-3"
                id="name"
                name="name"
                value={state.name}
                onChange={(value) => handleChange("name", value)}
                type="text"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-sm font-medium text-gray-700 dark:text-white"
                htmlFor="type"
              >
                Type
              </label>
              <Radios
                name="type"
                options={[
                  { label: "Interiors", value: "model1" },
                  { label: "Products", value: "model2" },
                  { label: "Sketch to life", value: "model3" },
                ]}
                value={state.type}
                onChange={(value) => handleChange("type", value)}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-sm font-medium text-gray-700 dark:text-white"
                htmlFor="description"
              >
                Description
              </label>
              <InputField
                extra="mb-4"
                id="description"
                name="description"
                value={state.description}
                onChange={(value) => handleChange("description", value)}
                rows={4}
                required
              />
            </div>
          </>
        )}

        <div className="bg-gray-50 px-4 py-3 dark:bg-navy-900 dark:text-white sm:flex sm:flex-row-reverse sm:px-6">
          {isDeleting ? (
            <>
              <Button
                label="Delete Project"
                type="button"
                onClick={handleSubmit}
                extras="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 dark:bg-red-500 sm:ml-3 sm:w-auto"
              />
            </>
          ) : (
            <Button
              label="Create Project"
              type="submit"
              extras="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 dark:bg-brand-400 sm:ml-3 sm:w-auto"
            />
          )}
          <Button
            label="Cancel"
            type="button"
            onClick={onClose}
            extras="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          />
        </div>
      </form>
    </>
  );
};

export default ProjectForm;
