import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import AuthLayout from "layouts/auth";
import GeneralLayout from "layouts/generalLayout";
import Home from "views/Home";
import Interiors from "views/Interiors";

const App = () => {
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route
        path="home"
        element={
          <GeneralLayout>
            <Home />
          </GeneralLayout>
        }
      />
      <Route
        path="interiors"
        element={
          <GeneralLayout>
            <Interiors />
          </GeneralLayout>
        }
      />
      <Route path="#" element={<GeneralLayout />} />
      <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
    </Routes>
  );
};

export default App;
