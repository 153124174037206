import React from "react";

const Prompt = ({ label, value }) => {
  return (
    <div>
      <h6 className="text-sm">{label}</h6>
      <button className="text-black dark:text-black dark rounded-xl border-2 px-2 py-1 text-center text-xs transition duration-200 hover:!bg-white/80 active:!bg-white/70">
        {value}
      </button>
    </div>
  );
};

export default Prompt;
