import React, { useRef, useEffect, useState } from "react";
import { MdEdit, MdOutlineModeEditOutline } from "react-icons/md";
import { PiEraserFill, PiEraser } from "react-icons/pi";
import { BsCloudDownload } from "react-icons/bs";

import sofa6 from "../../assets/images/sofa6.jpeg";
import FavouriteCard from "components/favouriteCard";
import Prompt from "components/prompt";
import InputField from "../../components/fields/InputField";
import Upload from "components/upload";
import { ReactComponent as LeftIcon } from "../../assets/icons/left.svg";
import { ReactComponent as RightIcon } from "../../assets/icons/right.svg";
import Control from "components/control";
import Button from "../../components/button";

const Interiors = () => {
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const containerRef = useRef(null);
  const fileInputRef = useRef(null);

  const [isDrawing, setIsDrawing] = useState(false);
  const [isErasing, setIsErasing] = useState(false);
  const [activeControl, setActiveControl] = useState(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = containerRef.current.clientWidth;
    canvas.height = 500;

    const context = canvas.getContext("2d");
    context.lineCap = "round";
    context.strokeStyle = "#005E7A";
    context.lineWidth = 22;
    contextRef.current = context;
  }, []);

  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
    setIsDrawing(true);
    nativeEvent.preventDefault();
  };

  const draw = ({ nativeEvent }) => {
    if (!isDrawing) {
      return;
    }

    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
    nativeEvent.preventDefault();
  };

  const stopDrawing = () => {
    contextRef.current.closePath();
    setIsDrawing(false);
  };

  const setToDraw = () => {
    setIsErasing(false);
    contextRef.current.globalCompositeOperation = "source-over";
  };

  const setToErase = () => {
    setIsErasing(true);
    contextRef.current.globalCompositeOperation = "destination-out";
  };

  const downloadImage = () => {
    const link = document.createElement("a");
    link.download = "canvas.png";
    link.href = canvasRef.current.toDataURL("image/png");
    link.click();
  };

  const handleImageUpload = () => {
    const file = fileInputRef.current.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        contextRef.current.drawImage(img, 0, 0);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="h-full rounded-[20px] bg-white p-3 dark:!bg-navy-800 dark:text-white ">
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
        <Control
          title="Generate"
          subtitle="Interior from Scratch"
          isActive={activeControl === "Generate"}
          onClick={() => setActiveControl("Generate")}
        />

        <Control
          title="Redecorate"
          subtitle="Upload your Image"
          isActive={activeControl === "Redecorate"}
          onClick={() => setActiveControl("Redecorate")}
        />

        <Control
          title="Furniture"
          subtitle="Add Furniture"
          isActive={activeControl === "Furniture"}
          onClick={() => setActiveControl("Furniture")}
        />
      </div>

      <div className="my-4 grid h-full grid-cols-1 gap-2 lg:grid-cols-3">
        <div className="flex h-full flex-col lg:col-span-2" ref={containerRef}>
          <div onClick={downloadImage} className="self-end">
            <BsCloudDownload className="mb-2 mr-2 h-9 w-9 text-brand-500" />
          </div>

          <canvas
            className="w-full rounded-[20px] border border-gray-300 cursor-crosshair"
            ref={canvasRef}
            onMouseDown={startDrawing}
            onMouseMove={draw}
            onMouseUp={stopDrawing}
            onMouseLeave={stopDrawing}
          />

          <div className="mt-2 flex justify-center pb-4">
            <LeftIcon className="h-9 w-9" />
            {!isErasing ? (
              <MdEdit onClick={setToDraw} className="h-9 w-9 text-brand-500" />
            ) : (
              <MdOutlineModeEditOutline
                onClick={setToDraw}
                className="h-9 w-9"
              />
            )}

            {isErasing ? (
              <PiEraserFill
                className="h-9 w-9 text-brand-500"
                onClick={setToErase}
              />
            ) : (
              <PiEraser className="h-9 w-9 " onClick={setToErase} />
            )}
            <RightIcon className="h-9 w-9" />
          </div>
        </div>

        <div className="flex flex-col justify-around">
          <InputField
            placeholder="anime, cartoon, graphic, text, painting, crayon, graphite, abstract, glitch"
            name="prompt"
            type="text"
            label="Prompt"
            extra="mb-3"
          />

          <div className="mb-3 flex items-center justify-between">
            <Prompt label="Environment" value="interior" />
            <Prompt label="Type" value="studio" />
            <Prompt label="Material" value="dark grey" />
            <Prompt label="Type" value="studio" />
            <Prompt label="Material" value="dark grey" />
          </div>

          <Upload
            onImageUpload={handleImageUpload}
            fileInputRef={fileInputRef}
          />

          <InputField
            placeholder="anime, cartoon, graphic, text, painting, crayon, graphite, abstract, glitch, deformed, mutated, ugly, disfigured"
            name="Negative Prompt"
            type="text"
            label="Negative Prompt"
          />

          <Button
            label="Generate now"
            extras="self-end text-white bg-red-500 hover:bg-red-600 active:bg-red-700"
          />
        </div>
      </div>

      <div className="mb-5 grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4">
        <FavouriteCard buttonText="Edit" imgUrl={sofa6} />

        <FavouriteCard buttonText="Edit" imgUrl={sofa6} />

        <FavouriteCard buttonText="Edit" imgUrl={sofa6} />

        <FavouriteCard buttonText="Edit" imgUrl={sofa6} />
      </div>
    </div>
  );
};

export default Interiors;
