import Button from "../button";

const Card = ({
  imageUrl,
  buttonLabel,
  onButtonClick,
  heading,
  subheading,
  description,
  btnExtras,
  children,
}) => {
  return (
    <div className="flex flex-col rounded-[20px] bg-white bg-clip-border p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none">
      {heading && <p className="my-1 text-base">{heading}</p>}
      {subheading && <p className="my-1 text-sm">{subheading}</p>}
      {imageUrl && (
        <img
          src={imageUrl}
          className="mb-3 w-full rounded-xl object-contain 3xl:h-full 3xl:w-full"
          alt=""
        />
      )}

      {description && (
        <p className="text-sm font-medium text-gray-600">{description}</p>
      )}
      {children}
      <div className="mt-1 self-end">
        {buttonLabel && (
          <Button
            label={buttonLabel}
            extras={btnExtras}
            onClick={onButtonClick}
          />
        )}
      </div>
    </div>
  );
};

export default Card;
