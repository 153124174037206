import React from "react";
import { FaTimes } from "react-icons/fa";

const Dialog = ({ isOpen, onClose, title, form: Form, onSubmit }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto dark:text-white">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all dark:bg-brand-400 sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 text-white dark:bg-navy-900 sm:p-6 sm:pb-4">
                <div className="flex items-center justify-between">
                  <h3
                    className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                    id="modal-title"
                  >
                    {title}
                  </h3>
                  <button
                    type="button"
                    onClick={onClose}
                    className="text-gray-900 focus:outline-none dark:text-white"
                    aria-label="Close"
                  >
                    <FaTimes size={20} />
                  </button>
                </div>

                <div className="mt-2">
                  <Form onSubmit={onSubmit} onClose={onClose} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dialog;
